<template>
    <div
        v-if="text || facts.length"
        class="course-description"
    >
        <div class="course-description__inner">
            <div v-if="text" class="course-description__text" v-html="text" /> <!-- eslint-disable-line -->
            <div
                v-if="facts"
                class="course-description__facts"
            >
                <div
                    v-for="(fact, i) in facts"
                    :key="i"
                    class="course-description__fact"
                >
                    <BaseIcon :icon="fact.icon" />
                    {{ fact.header }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
'use strict';

export default {
    props: {
        text: {
            type: String,
            default: ''
        },
        facts: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>

<style lang="less" src="./CourseDescription.less"></style>
