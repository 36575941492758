<template>
    <div class="hero-main">
        <div
            class="hero-main__top"
            :class="background"
        >
            <h1 class="hero-main__title">
                <span v-if="subtitle">{{ subtitle }}</span>
                {{ title }}
            </h1>
            <div
                v-if="anchorLinks.length"
                class="hero-main__actions"
            >
                <a
                    v-for="link in anchorLinks"
                    :key="link.anchorId"
                    :href="`#${link.anchorId}`"
                    class="button--outline button--small"
                >
                    {{ link.buttonLabel }}
                    <BaseIcon :icon="link.icon" />
                </a>
            </div>
        </div>
        <div 
            class="hero-main__bottom"
            :style="{ transform: `translateY(-${paralaxOffset}px)` }"
        >
            <slot name="course-description" />
            <div class="hero-main__image">
                <ImageAsset
                    :image="mainImage"
                    sizes="100vw"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useWindowScroll } from '@vueuse/core';
const { y } = useWindowScroll();

const paralaxOffset = computed(() => {
    const maxOffset = 30;
    return Math.min(y.value / 15, maxOffset);
});

const props = defineProps({
    subtitle: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    image: {
        type: Object,
        default() {
            return {};
        }
    },
    background: {
        type: String,
        default: ''
    },
    courseText: {
        type: String,
        default: ''
    },
    courseFacts: {
        type: Array,
        default() {
            return [];
        }
    },
    anchorLinks: {
        type: Array,
        default() {
            return [];
        }
    },
});

const { background, image } = toRefs(props);

const mainImage = computed(() => image.value);
const backgroundColorHex = computed(() => {
    switch (background.value) {
    case 'background--green':
        return '#004F41';
    case 'background--red':
        return '#BA4356';
    case 'background--blue':
        return '#006789';
    default:
        return '#fff';
    }
});

const head = computed(() => {
    return {
        meta: [
            {
                hid: 'theme-color', name: 'theme-color', content: backgroundColorHex.value
            }
        ]
    };
});

useHead(head);
</script>

<style lang="less" src="./Hero.less" />
