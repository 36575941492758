import { trim } from 'lodash-es';
import { HOME_URI } from '~/enums/page-uri';

export default function getCraftUri(currentPath, localePrefix = '') {
    let path = unref(currentPath);
    path = trim(path, '/');

    /* The craft live preview feature can add query params which need to be stripped
        in order to match the correct page / prevent 404's.
        */
    if (path.includes('?')) {
        path = path.substring(0, path.indexOf('?'));
    }

    // Strip any locale prefixes from path.
    if (localePrefix?.length) {
        const expression = new RegExp(`^/?${localePrefix}/?(.*)`, 'i');
        const matches = path.match(expression);

        if (matches?.length > 0) {
            path = matches[1] || '';
        }
    }

    // Replace any empty path with _home_
    if (!path.length || path === '/') {
        path = HOME_URI;
    }

    return path;
}
