<template>
    <div
        class="ft-widget-b2 formitable-loader"
        :data-restaurant="id"
        data-open="true"
        data-open-mobile="false"
        data-color="#923C3B"
        data-language="auto"
        data-tag="Website"
        data-toolbar="true"
        data-toolbar-mobile="true"
    />
</template>

<script setup>
const props = defineProps({
    id: {
        type: String,
        required: true,
        default: ''
    }
});

const { id } = toRefs(props);

const parseFormitable = () => {
    if (window?.FT?.parse) {
        window.FT.parse();
    }
};

const { push } = useTagmanager();

const handleOpenEvent = ()  => {
    push({
        event: 'formitable.open',
        data: id.value
    });
};
const handleOrderEvent = () => {
    push({
        event: 'formitable.ordered',
        data: id.value
    });
};

onMounted(() => {
    parseFormitable();

    window.addEventListener('ft-widget-open', handleOpenEvent);
    window.addEventListener('ft-widget-ordered', handleOrderEvent);
});

useHead({
    script: [
        {
            hid: 'formitable',
            src: '/assets/js/formitable.js'
        }
    ],
    bodyAttrs: {
        class: 'formitable-show'
    }
});
</script>

<style lang="less">
.formitable-loader {
    display: none !important; // stylelint-disable-line declaration-no-important
}

div.ft-widget-b2 {
    display: none !important; // stylelint-disable-line declaration-no-important
}

.formitable-show div.ft-widget-b2:not(.formitable-loader) {
    display: block !important; // stylelint-disable-line declaration-no-important
}
</style>
