<template>
    <article
        v-if="entry"
        id="main"
        role="main"
        tabindex="-1"
    >
        <TheNavigation
            v-if="!menuHidden"
            :call-to-action="callToAction"
            :class="(isMainPage || isLandingPage) ? 'the-navigation--light' : ''"
            :fixed="!(isMainPage || isLandingPage) && !!(image || imageMobile)"
            :return-to="isChildPage ? `/${parentUri}/` : null"
            :mews="isHotelPage ? mews : null"
        />
        <HeroLogo v-else />

        <HeroMain
            v-if="isMainPage"
            :title="entry.title"
            :subtitle="entry.preHeader"
            :anchor-links="entry.anchorLinks"
            :image="image"
            :background="backgroundColorHero"
        >
            <template #course-description>
                <CourseDescription
                    class="hero-main__course-description"
                    :text="courseText"
                    :facts="courseFacts"
                />
            </template>
        </HeroMain>
        <HeroLanding
            v-else-if="isLandingPage"
            :title="entry.title"
            :subtitle="entry.preHeader"
            :image="image"
            :image-mobile="imageMobile"
            :gif="gif"
            :description="entry.richTextSimple"
            :background="backgroundColorHero"
            :call-to-action="entry.linkSingle[0]"
        />
        <HeroPerson
            v-else-if="isPersonPage"
            :title="pageTitle"
            :description="pageDescription"
            :image="image"
        />
        <HeroArticle
            v-else-if="(isChildPage && !parentIsResearch) || image || imageMobile"
            :title="pageTitle"
            :description="pageDescription"
            :image="image"
            :image-mobile="imageMobile"
            :wide="!isChildPage"
            :mews="isHotelPage ? mews : null"
        />
        <HeroDefault
            v-else
            :title="pageTitle"
            :description="pageDescription"
            :show-breadcrumb="parentIsResearch"
        />

        <CourseDescription
            v-if="!isMainPage"
            :text="courseText"
            :facts="courseFacts"
        />

        <ClientOnly>
            <FormitableWidget
                v-if="showFormitable"
                :id="formitableId"
            />
            <UnibuddyBubble v-else-if="showUnibuddy" />
        </ClientOnly>

        <FlexibleSections :sections="entry?.flexibleSection ?? []" />
    </article>
</template>

<script setup>
import getPage from '~/graphql/queries/getPage.graphql';

import { ENTRY_TYPE_RESTAURANT, ENTRY_TYPE_MAIN, ENTRY_TYPE_LANDING, ENTRY_TYPE_HOTEL, ENTRY_TYPE_PERSON } from '~/constants/types';

const { $i18n } = useNuxtApp();
const { path } = useRoute();
const siteHandle = $i18n?.localeProperties?.value?.craftSiteHandle;
const variables = computed(() => {
    return {
        siteHandle,
        uri: getCraftUri(path, $i18n.locale?.value)
    };
});

const response = await useAsyncQuery({
    query: getPage,
    variables
});

const { craftEntry: entry } = useCraftPageEther(response);

const callToAction = computed(() => entry.value?.callToAction?.[0] ?? null);
const showUnibuddy = computed(() => entry.value?.showUnibuddy ?? false);
const pageDescription = computed(() => entry.value?.richTextSimple ?? '');
const pageTitle = computed(() => entry.value?.title ?? '');
const type = computed(() => entry.value?.__typename ?? '');
// const uri = computed(() => entry.value?.uri ?? '');
const formitableId = computed(() => entry.value?.formitableId ?? '');
const showFormitable = computed(() => type.value === ENTRY_TYPE_RESTAURANT && formitableId.value);
// const preHeader = computed(() => entry.value?.preHeader ?? '');
const image = computed(() => entry.value?.image?.[0] ?? null);
const imageMobile = computed(() => entry.value?.imageMobile?.[0] ?? null);
const gif = computed(() => entry.value?.gif ?? null);
const backgroundColorHero = computed(() => entry.value?.backgroundColorHero ?? 'background--brown');
const courseText = computed(() => entry.value?.courseDescription?.[0]?.richTextSimple ?? '');
const courseFacts = computed(() => entry.value?.courseDescription?.[0]?.facts ?? []);

const isMainPage = computed(() => type.value === ENTRY_TYPE_MAIN);
const isLandingPage = computed(() => type.value === ENTRY_TYPE_LANDING);
const isHotelPage = computed(() => type.value === ENTRY_TYPE_HOTEL);
const isPersonPage = computed(() => type.value === ENTRY_TYPE_PERSON);

const mews = computed(() => {
    return {
        mewsId: entry.value?.mewsId ?? '',
        btnText: entry.value?.btnText ?? null
    };
});

const parentUri = computed(() => entry.value?.parent?.uri ?? '');
const isChildPage = computed(() => entry.value?.parent?.id ?? false);
const parentIsResearch = computed(() => entry.value?.parent?.uri === 'research');
const theme = computed(() => entry.value?.theme ?? '');
const menuHidden = computed(() => entry.value?.menuHidden ?? false);

const head = computed(() => {
    return {
        bodyAttrs: {
            class: (theme.value && theme.value !== 'default') ? `theme--${theme.value}` : ''
        }
    };
});

useHead(head);
</script>
